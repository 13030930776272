
import React,{ useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';

import './App.css';

import Home from './pages/home';
import MoveHome from './pages/moveHome';
import VideoDetail from './pages/videoDetail';
import NutriVideos from './pages/nutriVideos';
import MindVideos from './pages/mindVideos';
import MoveVideos from './pages/moveVideos';
import MoveHomeContext from './context/moveHomeContext'


function App() {

  const updateData = (data) => {
    setData(data);
  }

  const [data,setData] = useState({});

  return (
    <MoveHomeContext.Provider value={{data:data,updateData}}>
      <div className="main__wrapper">
        <Router>
          <Switch>
            <Route path="/move" component={MoveHome} />
            <Route path="/mind" component={MindVideos} />
            <Route path="/nutri" component={NutriVideos} />
            <Route path="/move-videos" component={MoveVideos} />
            <Route path="/videos/:id" component={VideoDetail} />
            <Route exact path="/" component={Home} />
          </Switch>
        </Router>
      </div>
    </MoveHomeContext.Provider>


  );
}

export default App;
