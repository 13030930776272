import React, {useState} from 'react';
import VideoPlayer from '../components/videoPlayer';
import Col from '../components/col';
import Row from '../components/row';
import NavBar from '../components/navbar';
import useGATracking from '../hooks/useGATracking';

import 'video.js/dist/video-js.css';

const VideoDetail = (props) => {

  useGATracking();
  const {
    location
  } = props;


  let player;

  const [data] = useState(location.state);

  if(data.videourl){
    player = <VideoPlayer
      controls
      src={data.videourl}
      poster={data.images.big}
      />

  }

  return(
    <>
        <NavBar />
        <div
          className="player_wrapper"
          style={{backgroundColor:'#000000',height:'240px'}}>
          {player}
        </div>
        <Row>
          <Col>
            <h2>{data.name}</h2>
            <p>{data.text}</p>
          </Col>
        </Row>
    </>
  );
}

export default VideoDetail;
