const cdnBaseUri = 'https://cdn.makeitapp.com';

export const buildCdnImage = (image, userId, size) => `${cdnBaseUri}/${userId}/${size}/${image}`;

export function ellipsize(text,size=25){
  let parts = [
    (text.length>size ? text.substr(0,size) : text),
    (text.length>size ? "..." : "")
  ];

  return parts.join("");
};
