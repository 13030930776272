import React, { useState, useEffect, useContext } from "react";
import {Link} from 'react-router-dom';
import axios from 'axios';
import {buildCdnImage} from '../utils'
import {Conf} from '../conf';

import ListView from '../components/listView';
import Card from '../components/card';
import Col from '../components/col';
import Row from '../components/row';
import Heading from '../components/heading';
import NavBar from '../components/navbar';

import MoveHomeContext from '../context/moveHomeContext';
import useGATracking from '../hooks/useGATracking';

const MoveHome = ()=>{

  useGATracking();

  const [homeData,setHomeData] = useState({});

  const homeFeedUrl = `${Conf.BASE_API}/rest/groupped-content/home-move`;
  const HomeFeedContext = useContext(MoveHomeContext);

  useEffect(()=>{
    async function getHomeData(){
      let url = `${homeFeedUrl}?userid=${Conf.USER_ID}&$sort[0]=-episode_number&limit=5`
      const result = await axios(url);
      setHomeData(result.data);
      HomeFeedContext.updateData(result.data);
    }

    const cachedData = HomeFeedContext.data;
    if(Object.keys(cachedData).length===0){
      getHomeData();
      return;
    }

    setHomeData(cachedData);

  },[
    homeFeedUrl,
    HomeFeedContext
  ]);

  const categories = homeData.categories ? homeData.categories.data : [];
  const cardio = homeData.cardio ? homeData.cardio.data : [];
  const tone = homeData.tone ? homeData.tone.data : [];
  const olistic = homeData.mind ? homeData.mind.data : [];

  return (
      <>
      <NavBar />
      <Heading>Entrenamiento del día</Heading>

        <ListView
          height="490px"
          width="100%"
          edgeInsets="20px"
          direction="horizontal">
          {
            categories.map((item, i ) =>{
              item.imageUrl = buildCdnImage(item.cover,Conf.USER_ID,'640x0');
              return(
                <Card key={i}
                  width="266.66px"
                  gap="20px"
                  borderRadius="8px"
                  boxShadow="0 0 16px 0 rgba(0,0,0,0.1)"
                  style={{backgroundImage:`url(${item.imageUrl})`}}>
                  <Link to={{pathname:'/move-videos/',state:item}} className="link__wrapper"></Link>
                </Card>
              );
            })
          }
        </ListView>

      <Heading type="h2" style={{padding:"5px 20px 20px 20px"}}>Entrenamiento cardio</Heading>

      <ListView
        height="200px"
        width="100%"
        edgeInsets="0 20px"
        direction="horizontal">
        {
          cardio.map((item, i ) =>{
            return(
                <Card
                  key={i}
                  width="320px"
                  height="200px"
                  gap="15px"
                  borderRadius="8px"
                  style={{backgroundImage:`url(${item.images.big})`}}>
                  <Link to={{pathname:`/videos/${item.uniqueid}`,state:item}} className="link__wrapper" style={{backgroundImage:"linear-gradient(0deg, rgba(0,0,0,0.2),transparent)"}}>
                    <Col justifyContent="end">
                      <Row alignItems="center">
                        <Col>
                          <span style={{paddingBottom:'5px',fontSize:"14px"}} className="uppercase">{item.composite_episode_label}</span>
                          <strong className="uppercase">{item.name}</strong>
                        </Col>
                        <span className="uppercase badge">{item.audio_language}</span>
                      </Row>
                    </Col>
                  </Link>
                </Card>
            );
          })
        }
      </ListView>

      <Heading
        type="h2"
        style={{padding:"20px"}}>
        Entrenamiento tonificación
      </Heading>

      <ListView
        height="200px"
        width="100%"
        edgeInsets="0 20px"
        direction="horizontal">
        {
          tone.map((item, i ) =>{
            return(
              <Card
                key={i}
                width="320px"
                height="200px"
                gap="15px"
                borderRadius="8px"
                style={{backgroundImage:`url(${item.images.big})`}}>
                <Link to={{pathname:`/videos/${item.uniqueid}`,state:item}} className="link__wrapper" style={{backgroundImage:"linear-gradient(0deg, rgba(0,0,0,0.2),transparent)"}}>
                  <Col justifyContent="end">
                    <Row alignItems="center">
                      <Col>
                        <span style={{paddingBottom:'5px',fontSize:"14px"}} className="uppercase">{item.composite_episode_label}</span>
                        <strong className="uppercase">{item.name}</strong>
                      </Col>
                      <span className="uppercase badge">{item.audio_language}</span>
                    </Row>
                  </Col>
                </Link>
              </Card>
            );
          })
        }
      </ListView>

      <Heading
        type="h2"
        style={{padding:"20px"}}>
        Entrenamiento holístico
      </Heading>

      <ListView
        height="200px"
        width="100%"
        edgeInsets="0 20px"
        direction="horizontal">
        {
          olistic.map((item, i ) =>{
            return(
              <Card
                key={i}
                width="320px"
                height="200px"
                gap="15px"
                borderRadius="8px"
                style={{backgroundImage:`url(${item.images.big})`}}>
                <Link to={{pathname:`/videos/${item.uniqueid}`,state:item}}  className="link__wrapper" style={{backgroundImage:"linear-gradient(0deg, rgba(0,0,0,0.1),transparent)"}}>
                  <Col justifyContent="end">
                    <Row alignItems="center">
                      <Col>
                        <span style={{paddingBottom:'5px',fontSize:"14px"}} className="uppercase">{item.composite_episode_label}</span>
                        <strong className="uppercase">{item.name}</strong>
                      </Col>
                      <span className="uppercase badge">{item.audio_language}</span>
                    </Row>
                  </Col>
                </Link>
              </Card>
            );
          })
        }
      </ListView>

    </>
  )
}

export default MoveHome;
