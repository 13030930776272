import React from 'react';
import styles from '../styles/card.module.css';

const Card = (props) => {
  const {
    borderRadius=0,
    gap=0,
    itemsSpace=0,
    width='100%',
    height,
    boxShadow=null,
    style={},
    children
  } = props;

  style.boxShadow = boxShadow;
  style.borderRadius = borderRadius;
  style.flex = `0 0 ${width}`;
  style.marginRight = gap;
  style.marginBottom = itemsSpace;

  style.height=height;

  return(
    <div className={styles.card} style={style}>
    {
      children
    }
    </div>
  );
};

export default Card;
