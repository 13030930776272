import React from 'react';
import NavBar from '../components/navbar'
import {Link} from 'react-router-dom';
import ListView from '../components/listView'
import Card from '../components/card'
import useGATracking from '../hooks/useGATracking';

import moveHero from '../res/app_move_hero@2x.png' ;
import mindHero from '../res/app_mind_hero@2x.png' ;
//import nutriHero from '../res/app_nutri_hero@2x.png' ;

const menuItems = [
    {
      id:1,
      image:moveHero,
      location:"/move"
    },
    {
      id:2,
      image:mindHero,
      location:"/mind"
    }    
];

/*
{
  id:3,
  image:nutriHero,
  location:"/nutri"
}
*/

const Home = () => {

  useGATracking();

  return (
    <>
    <NavBar hideBackButton />
    <ListView
      width="100%"
      style={{maxWidth:`380px`,margin:`auto`}}
      edgeInsets="0 15px"
      direction="vertical">
      {
        menuItems.map((item)=>{
          return (
            <Card key={item.id}
              width="100%"
              height="200px"
              itemsSpace="20px"
              borderRadius="8px"
              boxShadow="0 0 16px 0 rgba(0,0,0,0.1)"
              style={{backgroundImage:`url(${item.image})`}}>
              <Link to={item.location} className="link__wrapper"></Link>
            </Card>
          )
        })
      }
    </ListView>
    </>
  )
}

export default Home;
