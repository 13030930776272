import {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import ReactGA from 'react-ga';
import {Conf} from '../conf';

const useGATracking = () =>{
  const location = useLocation();

  const [initialized, setInitialized] = useState(false);

  useEffect(
    ()=>{
      if(!window.location.href.includes("localhost") && Conf.UA_ID!==null && Conf.UA_ID!==''){
        ReactGA.initialize(Conf.UA_ID);
        setInitialized(true);
      }
    },
    []
  );

  useEffect(
    ()=>{
      let currentPage = `${location.pathname}${location.search}`;

      if (initialized) {
        ReactGA.pageview(currentPage);
        //console.log("location",currentPage);
      }
    },
    [
      location,
      initialized
    ]
  );

}

export default useGATracking;
