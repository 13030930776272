import React from 'react';
import styles from '../styles/tab.module.css';
import classNames from 'classnames/bind';

const Tab = (props) => {

  const {
    context,
    title,
    selectedItemId,
    onClick:onClickHandler
  } = props;

  const cx = classNames.bind(styles);

  let cn = cx({
    tab__container:true,
    tab__selected:selectedItemId===context.uniqueid
  });

  return (
      <button className={cn} onClick={()=>onClickHandler(context)}>{title}</button>
  );
}

export default Tab;
