import React from 'react';
import styles from '../styles/flex.module.css';
import classNames from 'classnames/bind';

const Row = (props) => {
  const {
    children,
    className,
    alignItems=false,
    justifyContent=false,
    style
  } = props;

  const cx = classNames.bind(styles);
  let cn =  cx({
    row:true,
    [`${className}`]:className!==undefined,
    [`align-${alignItems}`]:alignItems!==false,
    [`justify-${justifyContent}`]:justifyContent!==false
  });

  return (
    <div className={cn} style={{...style}}>
    {
      children
    }
    </div>
  );
}

export default Row;
