import React from 'react';
import styles from '../styles/listView.module.css';
import classNames from 'classnames/bind';

const ListView = (props) => {

  const {
    height,
    width,
    style,
    edgeInsets=0,
    children,
    direction="vertical"
  } = props;

  let cx = classNames.bind(styles);

  const containerClassName = cx({
    container:true,
    vertical:direction==='vertical',
    horizontal:direction==='horizontal'
  });

  return (
    <div className={styles.wrapper} style={{height:height}}>
      <div className={containerClassName} style={{width:width,padding:edgeInsets,...style}}>
      {
        children
      }
      </div>
    </div>
  )
}

export default ListView;
