import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import styles from '../styles/loader.module.css';

const LoadMore = (props) => {
  const {
    nextUrl,
    isLoading=false,
    onHasNext
  } = props;

  const { ref, inView } = useInView({
    threshold: 0,
  });

  const hasNext = (nextUrl) => {
    if(nextUrl!==undefined && nextUrl!==null && nextUrl.toUpperCase()!=='EOF'){
      return true;
    }

    return false;
  }

  let loader = (hasNext(nextUrl) && isLoading===false)?  <div ref={ref} className={styles.loader}>Loading...</div> : null;


  useEffect(() => {
    if(inView && hasNext(nextUrl) && isLoading===false){
      onHasNext(nextUrl);
    }
  });



  return(
    loader
  );
}

export default LoadMore;
