import React from 'react';
import styles from '../styles/heading.module.css';

const Heading = (props) => {
  const {
    type:HeadingType='h1',
    style,
    children
  } = props;

  return (
    <HeadingType className={styles[HeadingType]} style={style}>{
      children
    }</HeadingType>
  );
}

export default Heading;
