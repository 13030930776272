import React, { useState } from 'react';
import {useHistory} from 'react-router-dom';
import styles from '../styles/navbar.module.css';
import classNames from 'classnames/bind';

import chevronLeftIcon from '../res/chevron_left_icon.svg';

const NavBar = (props) => {
  const {
    title,
    titleUppercase=false,
    hideBackButton=false,
    alt=""
  } = props;

  const history = useHistory();
  const [titleBar] = useState(title);
  const [backButtonIsHidden] = useState(hideBackButton);
  const [titleIsUppercase] = useState(titleUppercase)

  const handleBackClick = ()=>{
      history.goBack();
  };

  const cx = classNames.bind(styles);

  let titleBarClassnames = cx({
    titleBar:true,
    uppercase:titleIsUppercase
  });

  let backButton;
  if(!backButtonIsHidden){
    backButton = <input className={styles.backButton} type="image" src={chevronLeftIcon} alt={alt} onClick={handleBackClick} />;
  }


  return(
    <div className={styles.navbar}>
      <div className={styles.leftWrapper}>
        {backButton}
      </div>
      <div className={titleBarClassnames}>{titleBar}</div>
      <div className={styles.rightWrapper}></div>
    </div>
  )
}

export default NavBar;
