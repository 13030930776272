import React from 'react';
import styles from '../styles/flex.module.css';
import classNames from 'classnames/bind';

let cx = classNames.bind(styles);

const Col = (props) => {
  const {
    children,
    alignItems=false,
    justifyContent=false,
    className=false,
    style

  } = props;

  let cn = cx({
    col:true,
    [`${className}`]:className!==false,
    [`align-${alignItems}`]:alignItems!==false,
    [`justify-${justifyContent}`]:justifyContent!==false
  });

  return (
    <div className={cn} style={{...style}}>
    {
      children
    }
    </div>
  );
}

export default Col;
