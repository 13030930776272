import React, {useState, useEffect, useContext} from "react";
import axios from 'axios';
import classNames from 'classnames';
import {Conf} from '../conf';
import {Link,useHistory} from 'react-router-dom';
import NavBar from '../components/navbar'
import Tabs, {Tab} from '../components/tabs';
import ListView from '../components/listView';
import Card from '../components/card';
import Col from '../components/col';
import Row from '../components/row';
import LoadMore from '../components/loadmore';

import MoveHomeContext from '../context/moveHomeContext';
import useGATracking from '../hooks/useGATracking';

const MoveVideos = (props) => {

  useGATracking();

  const {
    location
  } = props;

  const history = useHistory();
  const [galleryId,setGalleryId] = useState(location.state.uniqueid);
  const [categories,setCategories] = useState([]);
  const [data,setData] = useState([]);
  const [next,setNext] = useState('EOF');
  const [isLoading,setIsLoading] = useState(false);

  async function onHasNextHandler(nextUrl){
    if(isLoading)
      return;

    setIsLoading(true);
    const results = await axios(nextUrl);
    let newData = [...data,...results.data.data];

    setData(newData);
    setNext(results.data.next);
    setIsLoading(false);
  };

  const videosUrl = `${Conf.BASE_API}/rest/videogalleries`;

  const HomeFeedContext = useContext(MoveHomeContext);

  useEffect(()=>{
    async function getCategories(){
      let url = `${videosUrl}?userid=${Conf.USER_ID}&categories__name=move&$field[0]=uniqueid&$field[1]=name&$field[2]=ordered_index`
      const results = await axios(url);
      setCategories(results.data.data);
    }

    const cachedCategories = HomeFeedContext.data;

    if(Object.keys(cachedCategories).length===0){
      getCategories();
    }
    else{
      setCategories(cachedCategories.categories.data);
    }

  },[
    videosUrl,
    HomeFeedContext.data
  ]);

  useEffect(()=>{
    async function getData(){
      let url = `${videosUrl}/${galleryId}?userid=${Conf.USER_ID}&$sort[0]=episode_number&force_locale=es`
      const results = await axios(url);
      setData(results.data.data);
      setNext(results.data.next);
    }
    //window.scrollTo(0, 0);
    getData();

  },[
    galleryId,
    HomeFeedContext.data,
    videosUrl
  ]);

  const handleClick = (context)=>{
    setGalleryId(context.uniqueid);
    history.replace({ state:context });
  }

  const renderTab = (data) => {

    const tabStyle = {
      position:"sticky",
      top:"50px",
      backgroundColor:"#fff",
      marginBottom: "10px"
    };

    if(data.length>0){
      return(
        <Tabs
        edgeInsets="10px 20px"
        style={tabStyle}>
          {
            data.map((item,i)=>{
              return(
                <Tab
                  path='/move-videos/'
                  selectedItemId={galleryId}
                  context={item}
                  key={i}
                  title={item.name}
                  onClick={handleClick} />
              )
            })
          }
        </Tabs>
      )
    }
  }


  return (
    <>
      <NavBar
        title="Workout"
        titleUppercase={true} />

        {renderTab(categories)}

        <ListView
          width="100%"
          edgeInsets="0 20px"
          direction="vertical">
          {
            data.map((item, i ) =>{
              let badgeClassNames = classNames(
                {
                  uppercase:true,
                  badge:true,
                  basic:item.audience_level.toLowerCase()==='basic',
                  advanced:item.audience_level.toLowerCase()==='advanced'
                }
              );

              return(
                  <Link key={`${item.uniqueid}_${i}`} className="card__link" to={{pathname:`/videos/${item.uniqueid}`,state:item}}>
                    <Card
                      width="100%"
                      height="200px"
                      borderRadius="8px"
                      style={{backgroundImage:`url(${item.images.big})`}}>
                      <div className="link__wrapper" style={{backgroundImage:"linear-gradient(0deg, rgba(0,0,0,0.2),transparent)"}}>
                        <Col justifyContent="space-between">
                          <Row>
                            <Col>
                              <strong className={badgeClassNames}>{item.audience_level}</strong>
                            </Col>
                            <span className="uppercase badge rounded">{item.audio_language}</span>
                          </Row>
                          <Row>
                            <Col>
                              <strong className="uppercase">{item.composite_episode_label}</strong>
                            </Col>
                            <span className="uppercase badge" style={{whiteSpace:'nowrap'}}>{item.duration}</span>
                          </Row>
                        </Col>
                      </div>
                    </Card>
                    <Col className="card__footer">
                      <h3>{item.name}</h3>
                      <span className="label__light">{item.author}</span>
                    </Col>
                  </Link>

              );
            })
          }
          <LoadMore nextUrl={next} onHasNext={onHasNextHandler} isLoading={isLoading} />
        </ListView>

    </>
  );
}

export default MoveVideos;
