import React from 'react';
import styles from '../styles/tabs.module.css';
import Tab from './tab';

const Tabs = (props) => {

  const {
    height,
    width,
    style,
    edgeInsets=0,
    children
  } = props;

  return (
    <div className={styles.wrapper} style={{height:height,...style}}>
      <div className={styles.container} style={{width:width,padding:edgeInsets}}>
      {
        children
      }
      </div>
    </div>
  )
}

export default Tabs;

export {Tab};
