import React, {useState, useEffect} from "react";
import axios from 'axios';
import {ellipsize} from '../utils'
import {Conf} from '../conf';
import {Link,useHistory} from 'react-router-dom';
import NavBar from '../components/navbar'
import Tabs, {Tab} from '../components/tabs';
import ListView from '../components/listView';
import Card from '../components/card';
import Col from '../components/col';
import Row from '../components/row';
import LoadMore from '../components/loadmore';
import useGATracking from '../hooks/useGATracking';

const MindVideos = (props) => {

  useGATracking();

  const {
    location
  } = props;


  const history = useHistory();
  const [galleryId,setGalleryId] = useState();
  const [categories,setCategories] = useState([]);
  const [data,setData] = useState([]);

  const [next,setNext] = useState('EOF');
  const [isLoading,setIsLoading] = useState(false);

  async function onHasNextHandler(nextUrl){
    if(isLoading)
      return;

    setIsLoading(true);
    const results = await axios(nextUrl);
    let newData = [...data,...results.data.data];

    setData(newData);
    setNext(results.data.next);
    setIsLoading(false);
  };

  const videosUrl = `${Conf.BASE_API}/rest/videogalleries`;

  useEffect(()=>{
    async function getCategories(){
      let url = `${videosUrl}?userid=${Conf.USER_ID}&categories__uniqid=${Conf.MIND_CATEGORY_ID}&$field[0]=uniqueid&$field[1]=name&$field[2]=ordered_index`
      const results = await axios(url);


      if(location.state===undefined && (results.data.data[0].uniqueid!==undefined)){
        setGalleryId(results.data.data[0].uniqueid)
      }
      else if(location.state!==undefined && location.state.uniqueid!==undefined){
        setGalleryId(location.state.uniqueid);
      }

      setCategories(results.data.data);
    }

    getCategories();

  },[
    videosUrl,
    location.state
  ]);

  useEffect(()=>{
    async function getData(){
      let url = `${videosUrl}/${galleryId}?userid=${Conf.USER_ID}&$sort[0]=episode_number&force_locale=es`
      const results = await axios(url);
      setData(results.data.data);
      setNext(results.data.next);
    }

    if(galleryId!==undefined)
      getData();

  },[
    galleryId,
    videosUrl
  ]);

  const handleClick = (context)=>{
    setGalleryId(context.uniqueid);
    history.replace({ state:context });
  }

  const renderTab = (data) => {

    const tabStyle = {
      position:"sticky",
      top:"50px",
      backgroundColor:"#fff",
      marginBottom: "10px"
    };

    if(data.length>1){
      return(
        <Tabs
        edgeInsets="10px 20px"
        style={tabStyle}>
          {
            data.map((item,i)=>{
              return(
                <Tab
                  path='/move-videos/'
                  selectedItemId={galleryId}
                  context={item}
                  key={i}
                  title={item.name}
                  onClick={handleClick} />
              )
            })
          }
        </Tabs>
      )
    }
  }


  return (
    <>
      <NavBar
        title="Mindfulness"
        titleUppercase={true} />

        {renderTab(categories)}

        <ListView
          width="100%"
          edgeInsets="10px 20px"
          direction="vertical">
          {
            data.map((item, i ) =>{

              return(

                    <Card
                      key={`${item.uniqueid}_${i}`}
                      width="100%"
                      height="130px"
                      itemsSpace="20px"
                      borderRadius="8px"
                      boxShadow="0 0 16px 0 rgba(0,0,0,0.1)">
                      <Link className="link__wrapper" to={{pathname:`/videos/${item.uniqueid}`,state:item}}>
                        <Row className="no-padding" style={{flexBasis:'100%'}}>
                          <Card
                            width="130px"
                            height="130px"
                            style={{backgroundImage:`url(${item.images.big})`}}>
                            <div className="link__wrapper" style={{backgroundImage:"linear-gradient(0deg, rgba(0,0,0,0.2),transparent)"}}>
                              <Col
                                justifyContent="end"
                                alignItems="end"
                                style={{padding:'5px'}}>
                                <span className="uppercase badge" style={{whiteSpace:'nowrap'}}>{item.duration}</span>
                              </Col>
                            </div>
                          </Card>

                          <Col justifyContent="center" style={{padding:'15px'}}>
                            <span className="label__light">{item.author}</span>
                            <h3 className="label__title" style={{margin:'5px 0'}}>{ellipsize(item.name)}</h3>
                            <span className="label__light uppercase">{item.audio_language}</span>
                          </Col>
                        </Row>
                      </Link>
                    </Card>


              );
            })
          }
          <LoadMore nextUrl={next} onHasNext={onHasNextHandler} isLoading={isLoading} />
        </ListView>

    </>
  );
}

export default MindVideos;
